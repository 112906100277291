import React from "react";
import { Navigate } from "react-router-dom";

export default function CashierPrivateRoute({ cashier, children }) {
  if (!cashier) {
    return <Navigate to="/login/lost" />;
  } else {
    return children;
  }
}
