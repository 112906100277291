import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { FaUserAlt, FaMobile, FaStore } from "react-icons/fa";
import { RiLockPasswordFill, RiLockPasswordLine } from "react-icons/ri";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createCashier } from "../../../redux/features/ownerSlice";
import { useTranslation } from "react-i18next";
import ChangeLang from "../../changeLang/ChangeLang";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";
import "./CreateCashier.css";
import "./CreateCashier.rtl.css";


export default function CreateCashier() {
  const addCashierValidation = yup.object().shape({
    firstName: yup.string().required("cashierFirstNameReq"),
    lastName: yup.string().required("cashierLastNameReq"),
    mobileNumber: yup.string().required("cashierMobileReq"),
    governate: yup.string().required("storeGovReq"),
    city: yup.string().required("storeCityReq"),
  });

  const [cities, setCities] = useState([]);
  const [governates, setGovernates] = useState([]);
  const [govID, setGovID] = useState("");
  const { t, i18n } = useTranslation();
  const merchantID = useSelector(
    (state) => state.reducer.owner.login.merchantID
  );
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGovernates(res.data["Governates List"]);
      });
  }, []);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govID,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [govID]);

  const navigate = useNavigate();

  return (
    <div className="create-cashier py-3 px-3">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header py-2 pb-1 d-flex justify-content-start"
          >
            <ChangeLang />
            <Button
              variant="danger"
              size="sm"
              className="mx-2"
              onClick={() => {
                navigate("/manager/home");
              }}
            >
              <FaHome />
            </Button>
          </Col>
          <Col xs={12} className="layout-body py-3">
            <Col xs={12} className="add-cashier-content px-3 px-sm-5 py-4">
              <h5 className="add-cashier-title">{t("addCashier")}</h5>
              <div className="add-cashier-form px-4 px-md-5 py-3 py-md-4">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    mobileNumber: "",
                    governate: "",
                    city: "",
                  }}
                  onSubmit={(values) => {
                    const loadReq = toast.loading(t("wait"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    dispatch(
                      createCashier({
                        governateID: values.governate,
                        cityID: values.city,
                        merchantID,
                        mobileNumber: values.mobileNumber,
                        firstName: values.firstName,
                        lastName: values.lastName,
                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data["message Ar"],
                          loadReq
                        );
                        navigate("/manager/home");
                      } else {
                        failedMessage(
                          res.payload.data.message,
                          res.payload.data["message Ar"],
                          loadReq
                        );
                      }
                    });
                  }}
                  validationSchema={addCashierValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12} className="mb-lg-3">
                          <Row>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                              <Form.Group as={Row}>
                                <Form.Label column xs={12} md={5} lg={5}>
                                  <FaUserAlt className="mx-2" />
                                  {t("firstName")} :
                                </Form.Label>
                                <Col xs={12} md={7} lg={7}>
                                  <Form.Control
                                    type="text"
                                    size="md"
                                    className="add-cashier-input-field"
                                    id="firstName"
                                    name="firstName"
                                    onChange={handleChange}
                                    value={values.firstName}
                                  />
                                  {errors.firstName && touched.firstName && (
                                    <Form.Text className="add-cashier-text-error">
                                      {t(errors.firstName)}
                                    </Form.Text>
                                  )}
                                </Col>
                              </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                              <Form.Group as={Row}>
                                <Form.Label column xs={12} md={5} lg={5}>
                                  <FaUserAlt className="mx-2" />
                                  {t("lastName")} :
                                </Form.Label>
                                <Col xs={12} md={7} lg={7}>
                                  <Form.Control
                                    type="text"
                                    size="md"
                                    className="add-cashier-input-field"
                                    id="lastName"
                                    name="lastName"
                                    onChange={handleChange}
                                    value={values.lastName}
                                  />
                                  {errors.lastName && touched.lastName && (
                                    <Form.Text className="add-cashier-text-error">
                                      {t(errors.lastName)}
                                    </Form.Text>
                                  )}
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} lg={6} className="mb-3">
                          <Form.Group as={Row}>
                            <Form.Label column xs={12} md={5} lg={5}>
                              <FaMobile className="mx-2" />
                              {t("mobileNumber")} :
                            </Form.Label>
                            <Col xs={12} md={7} lg={7}>
                              <Form.Control
                                type="text"
                                size="md"
                                maxLength={11}
                                className="add-cashier-input-field"
                                id="mobileNumber"
                                name="mobileNumber"
                                onChange={handleChange}
                                value={values.mobileNumber}
                                onKeyPress={(e) => {
                                  var char = String.fromCharCode(e.which);
                                  if (!/[0-9]/.test(char)) {
                                    e.preventDefault();
                                  }
                                }}
                                onPaste={(e) => {
                                  e.preventDefault();
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                }}
                              />
                              {errors.mobileNumber && touched.mobileNumber && (
                                <Form.Text className="add-cashier-text-error">
                                  {t(errors.mobileNumber)}
                                </Form.Text>
                              )}
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col xs={12} lg={12} className="mb-3">
                          <Row>
                            <Col xs={12}>
                              <Form.Group as={Row}>
                                <Form.Label column xs={12} lg={2}>
                                  <FaStore className="mx-2" />
                                  {t("storeInfo")} :
                                </Form.Label>
                                <Col
                                  xs={12}
                                  lg={10}
                                  className="store-select-group px-4 py-4"
                                >
                                  <Row>
                                    <Col
                                      xs={12}
                                      md={6}
                                      className="mb-3 mb-md-0 px-4"
                                    >
                                      <Form.Select
                                        size="sm"
                                        className="governate-select-add-cashier"
                                        name="governate"
                                        id="governate"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "governate",
                                            e.target.value
                                          );
                                          setGovID(e.target.value);
                                        }}
                                      >
                                        <option value="">
                                          {t("selectGov")}
                                        </option>
                                        {governates.map((gov) => {
                                          return (
                                            <option
                                              value={gov.GovernateID}
                                              key={gov.GovernateID}
                                            >
                                              {gov.governorate_name_en}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      {errors.governate &&
                                        touched.governate && (
                                          <Form.Text className="add-cashier-text-error">
                                            {t(errors.governate)}
                                          </Form.Text>
                                        )}
                                    </Col>
                                    <Col xs={12} md={6} className="px-4">
                                      <Form.Select
                                        size="sm"
                                        className="city-select-add-cashier"
                                        name="city"
                                        id="city"
                                        onChange={(e) => {
                                          setFieldValue("city", e.target.value);
                                        }}
                                        disabled={
                                          cities.length > 0 ? false : true
                                        }
                                      >
                                        <option value="">
                                          {t("selectCity")}
                                        </option>
                                        {cities.map((cit) => {
                                          return (
                                            <option
                                              value={cit.cityID}
                                              key={cit.cityID}
                                            >
                                              {cit.city_name_en}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      {cities.length > 0 &&
                                        errors.city &&
                                        touched.city && (
                                          <Form.Text className="add-cashier-text-error">
                                            {t(errors.city)}
                                          </Form.Text>
                                        )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={{ span: 4, offset: 4 }}
                          className="mt-2 d-grid"
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            type="submit"
                            className="add-new-cashier-btn"
                          >
                            {t("add")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2">
            <img src={require("../../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
