import React, { useState, useEffect } from "react";
import { Form, Badge, Button } from "react-bootstrap";
import { BsUpcScan } from "react-icons/bs";
import { Formik } from "formik";
import * as Yup from "yup";
import { sendOTPRedeem } from "../../../redux/features/cashierSlice";
import ScanBarcode from "./ScanBarcode";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { handleStartRedeemTimer } from "../../../redux/features/customerSlice";
import { handleSecondStepActive } from "../../../redux/features/customerSlice";
import { toast, Zoom } from "react-toastify";
import failedMessage from "../../messages/Failed";
import successMessage from "../../messages/Successful";
import "./Redeem.css";

export default function RedeemFirstStep() {
  const [stopscan, setStopscan] = useState(false);
  const [stopmanual, setStopmanual] = useState(false);
  const [showScanModal, setShowScanModal] = useState(false);
  const dispatch = useDispatch();

  const scannedCode = useSelector(
    (state) => state.reducer.cashier.scanBarcode.barcode
  );
  const scanModal = useSelector(
    (state) => state.reducer.cashier.scanBarcode.barcodeModalShow
  );
  const merchantName = useSelector(
    (state) => state.reducer.cashier.login.merchantName
  );

  const handleScanView = () => {
    setStopscan(false);
    setStopmanual(true);
  };
  const handleManualView = () => {
    setStopscan(true);
    setStopmanual(false);
  };
  const firstStepValidate = Yup.object().shape({
    bardcodeValue: Yup.string().required("scanReq"),
  });

  const firstActive = useSelector((state) => state.customer.scanBarcode.active);
  const [status, setStatus] = useState(false);
  useEffect(() => {
    setStatus(firstActive);
  }, [firstActive]);

  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="redeem-step">
        {status === false && <div className="overlay-not-active"></div>}
        <h5 className="redeem-title mb-3">{t("firstStep")}</h5>
        <Formik
          initialValues={{ bardcodeValue: scannedCode ? scannedCode : "" }}
          onSubmit={(values) => {
            dispatch(
              sendOTPRedeem({
                merchantName,
                barcode: values.bardcodeValue,
              })
            ).then((res) => {
              const loadReq = toast.loading(t("wait"), {
                transition: Zoom,
                position:
                  i18n.language === "ar" ? "bottom-right" : "bottom-left",
              });
              if (res.payload.data.statusCode === 200) {
                setStatus(false);
                dispatch(
                  handleSecondStepActive({
                    ScanBarcodeActive: false,
                    burnBarcodeActive: true,
                  })
                );
                dispatch(handleStartRedeemTimer({ startTimer: true }));
                successMessage(
                  res.payload.data.message,
                  res.payload.data["message Ar"],
                  loadReq
                );
              } else {
                failedMessage(
                  res.payload.data.message,
                  res.payload.data["message Ar"],
                  loadReq
                );
              }
            });
          }}
          validationSchema={firstStepValidate}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div
                className="scan-content px-3 pt-3 pb-2"
                onClick={() => {
                  handleScanView();
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Label className="step-label">
                    {t("scanBarcode")}
                    <Badge
                      bg="secondary"
                      className="scan-icon-content ms-2 px-4"
                      onClick={() => {
                        setShowScanModal(true);
                      }}
                    >
                      <BsUpcScan className="scan-icon" />
                    </Badge>
                  </Form.Label>
                  <Form.Control
                    disabled={stopscan ? true : false}
                    type="text"
                    placeholder={stopscan ? "" : t("scanBarcode")}
                    size="lg"
                    className="redeem-input"
                    id="bardcodeValue"
                    name="bardcodeValue"
                    onChange={handleChange}
                    value={stopscan ? "" : values.bardcodeValue}
                  />
                  {stopscan
                    ? ""
                    : errors.bardcodeValue &&
                      touched.bardcodeValue && (
                        <Form.Text className="text-redeem-error">
                          {t(errors.bardcodeValue)}
                        </Form.Text>
                      )}
                </Form.Group>
              </div>
              <h6 className="py-2 mb-0">{t("or")}</h6>
              <div
                className="manual-content px-3 pt-3 pb-2"
                onClick={() => {
                  handleManualView();
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Label className="step-label">
                    {t("manualBacrcode")}
                  </Form.Label>
                  <Form.Control
                    disabled={stopmanual ? true : false}
                    type="text"
                    placeholder={stopmanual ? "" : t("enterBarcode")}
                    size="lg"
                    className="redeem-input"
                    id="bardcodeValue2"
                    name="bardcodeValue"
                    onChange={handleChange}
                    value={stopmanual ? "" : values.bardcodeValue}
                  />
                  {stopmanual
                    ? ""
                    : errors.bardcodeValue &&
                      touched.bardcodeValue && (
                        <Form.Text className="text-redeem-error">
                          {t(errors.bardcodeValue)}
                        </Form.Text>
                      )}
                </Form.Group>
              </div>
              <div className="text-center mt-3">
                <Button
                  variant="danger"
                  className="send-otp-btn px-5 py-2"
                  size="sm"
                  type="submit"
                >
                  {t("sendOTP")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ScanBarcode
        show={showScanModal}
        onHide={() => {
          setShowScanModal(false);
        }}
      />
    </div>
  );
}
