import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Login from "./components/login/Login";
import ManagerHome from "./components/manager/home/ManagerHome";
import Redeem from "./components/cashier/redeem/Redeem";
import CashierHome from "./components/cashier/home/CashierHome";
import CashierReport from "./components/cashier/report/CashierReport";
import CustomerHome from "./components/customer/home/CustomerHome";
import FirstStep from "./components/customer/steps/first/FirstStep";
import SecondStep from "./components/customer/steps/second/SecondStep";
import ThirdStep from "./components/customer/steps/third/ThirdStep";
import CreateCashier from "./components/manager/createCashier/CreateCashier";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "animate.css";
import Congrats from "./components/customer/steps/congrats/Congrats";
import OwnerPrivateRoute from "./routes/OwnerPrivateRoute";
import CashierPrivateRoute from "./routes/CashierPrivateRoute";
import { useTranslation } from "react-i18next";
import NotFoundLogin from "./components/notfound/NotFoundLogin";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import VoucherPrivateRoute from "./routes/VoucherPrivateRoute";
import { handleCashierLogout } from "./redux/features/cashierSlice";
import { persistor } from "./index";
import "./App.css";
import AutoLogout from "./components/logout/AutoLogout";

function App() {
  const vouchs = useSelector((state) => state.customer.firstStep.vouchers);
  const merchant = useSelector((state) => state.reducer.owner.login.merchantID);
  const cashier = useSelector((state) => state.reducer.cashier.login.cashierID);

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const dispatch = useDispatch();

  useEffect(() => {
    setLang(i18n.language);
  });

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<Navigate to="/customer/home" />}></Route>
          <Route path="/customer/home" element={<CustomerHome />}></Route>
          <Route
            path="/customer/verify/receipt"
            element={<FirstStep />}
          ></Route>
          <Route
            path="/customer/choose/voucher"
            element={
              <VoucherPrivateRoute vouchs={vouchs}>
                <SecondStep />
              </VoucherPrivateRoute>
            }
          ></Route>
          <Route
            path="/customer/info"
            element={
              <VoucherPrivateRoute vouchs={vouchs}>
                <ThirdStep />
              </VoucherPrivateRoute>
            }
          ></Route>
          <Route
            path="/customer/gift/congrats"
            element={
              <VoucherPrivateRoute vouchs={vouchs}>
                <Congrats />
              </VoucherPrivateRoute>
            }
          ></Route>
          {merchant && cashier ? (
            <Route
              path="/eVoucher/:merchant/login"
              element={<Navigate to="/customer/home" />}
            ></Route>
          ) : (
            <Route path="/eVoucher/:merchant/login" element={<Login />}></Route>
          )}
          <Route
            path="/manager/home"
            element={
              <OwnerPrivateRoute merchant={merchant}>
                <ManagerHome />
              </OwnerPrivateRoute>
            }
          ></Route>
          <Route
            path="/manager/add/cashier"
            element={
              <OwnerPrivateRoute merchant={merchant}>
                <CreateCashier />
              </OwnerPrivateRoute>
            }
          ></Route>
          <Route
            path="/cashier/home"
            element={
              <CashierPrivateRoute cashier={cashier}>
                <CashierHome />
              </CashierPrivateRoute>
            }
          ></Route>
          <Route
            path="/cashier/redeem"
            element={
              <CashierPrivateRoute cashier={cashier}>
                <Redeem />
              </CashierPrivateRoute>
            }
          ></Route>
          <Route
            path="/cashier/report"
            element={
              <CashierPrivateRoute cashier={cashier}>
                <CashierReport />
              </CashierPrivateRoute>
            }
          ></Route>
          <Route path="/login/lost" element={<NotFoundLogin />}></Route>
        </Routes>
      </Router>
      {lang === "ar" ? (
        <ToastContainer className="toast-style" rtl pauseOnFocusLoss={false} />
      ) : (
        <ToastContainer className="toast-style" pauseOnFocusLoss={false} />
      )}
      <AutoLogout />
    </div>
  );
}

export default App;
