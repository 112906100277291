import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Scanner from "./Scanner";
import { useSelector } from "react-redux";
import "./ScanBarcod.css";

export default function ScanBarcode(props) {
  const scannedCode = useSelector(
    (state) => state.reducer.cashier.scanBarcode.barcode
  );

  return (
    <div className="scanBarcode">
      <Modal {...props} size="md" centered>
        <Modal.Body>
          <Scanner closeModal={()=>{
            props.onHide();
          }} />
          <Form.Group className="mt-3 mb-0">
            <Form.Control
            className="text-center scanCode"
              type="text"
              value={scannedCode ? scannedCode : ""}
              disabled={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <div className="w-100 d-grid">
            <Button variant="dark" onClick={props.onHide}>
              CLOSED
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
