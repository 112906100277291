import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./ChangeLang.css";

export default function ChangeLang(props) {
  const { t, i18n } = useTranslation();
  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
  };



  return (
    <div className="change-language">
      {i18n.language === "ar" ? (
        <Button
          variant="light"
          size="sm"
          className="lang-btn"
          onClick={() => {
            handleChangeLang("en");
            document.body.dir = "ltr"
          }}
        >
          انجليزي
        </Button>
      ) : (
        <Button
          variant="light"
          size="sm"
          className="lang-btn"
          onClick={() => {
            handleChangeLang("ar");
            document.body.dir = "rtl"
          }}
        >
          Arabic
        </Button>
      )}
    </div>
  );
}
