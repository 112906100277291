import React, { useEffect, useState } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { HiDocumentDownload } from "react-icons/hi";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import ChangeLang from "../../changeLang/ChangeLang";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import "./CashierReport.css";

export default function CashierReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [gridApi, setGridApi] = useState("");

  const mobileNumber = useSelector(
    (state) => state.reducer.cashier.login.mobileNumber
  );
  const merchantName = useSelector(
    (state) => state.reducer.cashier.login.merchantName
  );
  const [reports, setReports] = useState([]);

  const columnDefs = [
    {
      headerName: "eVoucher Acceptor",
      field: "eVoucher card acceptor",
    },
    {
      headerName: "eVoucher Creator",
      field: "eVoucher card creator",
    },
    {
      headerName: "Name",
      field: "Voucher Name",
    },
    {
      headerName: "Value",
      field: "eVoucher Value",
    },
    {
      headerName: "Mobile Number",
      field: "Customer Mobilenumber",
    },
    {
      headerName: "Card Status",
      field: "Card Status",
    },
  ];

  const defaultColDef = {
    editable: false,
    filter: true,
    floatingFilter: false,
    resizable: false,
    minWidth: 150,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        "Cashier Mobilenumber": mobileNumber,
        "Acquirer Name": merchantName,
        Function: "CashierGetReport",
      })
      .then((res) => {
        params.api.applyTransaction({ add: res.data.Transactions });
      });
  };

  const handleExport = () => {
    gridApi.exportDataAsCsv();
  };

  const navigate = useNavigate();
  return (
    <div className="cashier-report py-3">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header py-2 pb-1 d-flex justify-content-start"
          >
            <ChangeLang />
            <Button
              variant="danger"
              size="sm"
              className="mx-2"
              onClick={() => {
                navigate("/cashier/home");
              }}
            >
              <FaHome />
            </Button>
          </Col>
          <Col xs={12} className="layout-body py-3">
            <Row>
              <h4 className="report-title mb-3">Reports</h4>
              <Col xs={12}>
                <div className="reports-content">
                  <div className="ag-theme-alpine reports-table">
                    <AgGridReact
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                      pagination={true}
                      paginationAutoPageSize={true}
                    />
                  </div>
                  <div className="my-2 mx-2">
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => {
                        handleExport();
                      }}
                    >
                      <HiDocumentDownload className="mx-1" />
                      Download Report
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2">
            <img src={require("../../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
