import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ChangeLang from "../changeLang/ChangeLang";
import { useTranslation } from "react-i18next";
import "./NotFoundLogin.css";
import "./NotFoundLogin.rtl.css";

export default function NotFoundLogin() {
  const navigate = useNavigate();
  const merchantNameValidation = yup.object().shape({
    merchantName: yup
      .string()
      .required("merchantNameReq")
      .matches(/^[a-zA-Z0-9 ]*$/, "merchantNamenotValid"),
  });

  const { t } = useTranslation();

  return (
    <div className="login-notfound">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header py-2 pb-1 d-flex justify-content-start"
          >
            <ChangeLang />
          </Col>
          <Col xs={12} className="layout-body d-flex align-items-center">
            <Row>
              <Col
                xs={{ span: 8, offset: 2 }}
                md={{ span: 6, offset: 3 }}
                lg={{ span: 4, offset: 4 }}
                className="text-center"
              >
                <img
                  src={require("../../imgs/cust.png")}
                  className="img-responsive img-call w-100 animate__animated animate__headShake animate__infinite"
                  alt="call-icon"
                />
              </Col>
              <Col
                xs={12}
                sm={{ span: 8, offset: 2 }}
                lg={{ span: 6, offset: 3 }}
              >
                <Formik
                  initialValues={{ merchantName: "" }}
                  onSubmit={(values) => {
                    const merchant = values.merchantName;
                    const newArr = merchant.split(" ");
                    if (newArr.length > 1) {
                      for (var i = 0; i < newArr.length; i++) {
                        newArr[i] = newArr[i].toLowerCase();
                      }
                      const merchStr = newArr.join("_");
                      navigate(`/eVoucher/${merchStr}/login`);
                    } else {
                      navigate(`/eVoucher/${merchant}/login`);
                    }
                  }}
                  validationSchema={merchantNameValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          className="input-merchant-text"
                          name="merchantName"
                          id="merchantName"
                          placeholder={t("EnterMerchantName")}
                          onChange={handleChange}
                          value={values.merchantName}
                        />
                        {errors.merchantName && touched.merchantName && (
                          <Form.Text className="text-name-error">
                            {t(errors.merchantName)}
                          </Form.Text>
                        )}
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          variant="danger"
                          type="submit"
                          className="get-login-btn"
                        >
                          {t("getLoginBtn")}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2 pt-3">
            <img src={require("../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
