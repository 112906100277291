import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTimer } from "react-timer-hook";
import "./Redeem.css";

export default function Timer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
    autoStart: false,
  });

  const startTimer = useSelector((state) => state.customer.otpredeem.start);

  useEffect(() => {
    if (startTimer === true) {
      start();
    }
  }, [startTimer]);

  return (
    <div className="d-inline font-time">
      {" "}
      {minutes}:{seconds}
    </div>
  );
}
