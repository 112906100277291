import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import i18n from "i18next";

export const ownerLogin = createAsyncThunk(
  "owner/login",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Owner MobileMobileNumber": data.mobileNumber,
          "Vendor Name": data.merchantName,
          Function: "OwnerSendOTP",
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const ownerOtpSubmit = createAsyncThunk(
  "owner/otp",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Owner MobileMobileNumber": data.mobileNumber,
          "Vendor Name": data.merchantName,
          "OTP Code": data.otp,
          Function: "OwnerLogin",
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createCashier = createAsyncThunk(
  "owner/create/cashier",
  async (info, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          GovernateID: info.governateID,
          CitryID: info.cityID,
          VendorID: info.merchantID,
          "Cashier MobileMobileNumber": info.mobileNumber,
          "First Name": info.firstName,
          "Last Name": info.lastName,
          Function: "CreateCashier",
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const activeCashier = createAsyncThunk(
  "manager/active/cashier",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Cashier MobileMobileNumber": data.mobileNumber,
          Function: "ActivateCashier",
        }
      );
      return res;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deactiveCashier = createAsyncThunk(
  "manager/deactive/cashier",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Cashier MobileMobileNumber": data.mobileNumber,
          Function: "DeactivateCashier",
        }
      );
      return res;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteCashier = createAsyncThunk(
  "manager/delete/cashier",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Cashier MobileMobileNumber": data.mobileNumber,
          Function: "DeleteCashier",
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  login: {
    type: "",
    mobileNumber: "",
    merchantID: "",
    password: "",
    remember: false,
    message: "",
    messageAr: "",
  },
  merchantInfo: {
    merchantName: "",
    category: "",
    credit: "",
    debit: "",
    logoImage: "",
    vouchersGenerated: "",
    vouchersRedeemed: "",
  },
  createCashier: {
    message: "",
    messageAr: "",
  },
  activeCashier: {
    message: "",
    messageAr: "",
  },
  deactiveCashier: {
    message: "",
    messageAr: "",
  },
  deleteCashier: {
    message: "",
    messageAr: "",
  },
};

export const ownerSlice = createSlice({
  name: "owner",
  initialState,
  reducers: {
    handleOwnerLogout: (state, action) => {
      state.login.merchantID = "";
    },
  },
  extraReducers: {
    [ownerLogin.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.login.message = action.payload.data.message;
        state.login.messageAr = action.payload.data.messageAr;
      }
    },
    [ownerOtpSubmit.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.login.merchantID = action.payload.data.MerchantID;
        state.login.message = action.payload.data.message;
        state.login.messageAr = action.payload.data["message Ar"];
        state.merchantInfo.category = action.payload.data.Category;
        state.merchantInfo.credit = action.payload.data.Credit;
        state.merchantInfo.debit = action.payload.data.Debit;
        state.merchantInfo.logoImage = action.payload.data.LogoImageURL;
        state.merchantInfo.vouchersGenerated =
          action.payload.data["Vouchers Generated"];
        state.merchantInfo.vouchersRedeemed =
          action.payload.data["Vouchers Redeemed"];
        state.login.mobileNumber = action.meta.arg.mobileNumber;
        state.merchantInfo.merchantName = action.meta.arg.merchantName;
      }
    },
    [createCashier.fulfilled]: (state, action) => {
      state.createCashier.message = action.payload.data.message;
      state.createCashier.messageAr = action.payload.data["message Ar"];
    },
    [activeCashier.fulfilled]: (state, action) => {},
    [deactiveCashier.fulfilled]: (state, action) => {},
    [deleteCashier.fulfilled]: (state, action) => {},
  },
});

export const { handleOwnerLogout } = ownerSlice.actions;
export default ownerSlice.reducer;
