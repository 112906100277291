import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ownerLogin } from "../../redux/features/ownerSlice";
import LoginOTP from "./LoginOTP";
import { cashierLogin, handleCashierLogout } from "../../redux/features/cashierSlice";
import Cookies from "js-cookie";
import { FaUserAlt, FaUserTie } from "react-icons/fa";
import { useSelector } from "react-redux";
import ChangeLang from "../changeLang/ChangeLang";
import failedMessage from "../messages/Failed";
import successMessage from "../messages/Successful";
import { toast, Zoom } from "react-toastify";
import "./Login.css";
import "./Login.rtl.css";

export default function Login() {
  const [otpModalShow, setOtpModalShow] = useState(false);
  const [merchName, setMerchName] = useState("");
  const { t, i18n } = useTranslation();
  const merchantName = useParams("merchant");
  const [accountType, setAccountType] = useState("");
  const dispatch = useDispatch();

  const editMerchantName = (name) => {
    const merchantArr = name.split("_");
    for (var i = 0; i < merchantArr.length; i++) {
      merchantArr[i] =
        merchantArr[i].charAt(0).toUpperCase() + merchantArr[i].substr(1);
    }
    const merchStr = merchantArr.join(" ");
    return merchStr;
  };

  useEffect(() => {
    Cookies.remove("shopName");
    const merchantNamee = merchantName.merchant;
    const newMerchantNAme = editMerchantName(merchantNamee);
    setMerchName(newMerchantNAme);
  }, []);

  const [mobile, setMobile] = useState("");
  const loginValidation = yup.object().shape({
    mobileNumber: yup
      .string()
      .required("mobilereq")
      .matches(/^01[0125][0-9]{8}$/gm, "mobilenotValid"),
    accountType: yup.string().required("accountTypeReq"),
  });

  const merchant = useSelector((state) => state.reducer.owner.login.merchantID);
  const cashier = useSelector((state) => state.reducer.cashier.login.cashierID);



  return (
    <div className="login">
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <ChangeLang />
          </Col>
          <Col xs={12} className="mb-4">
            <h4 className="login-page-title">{t("WelcomeDandy")}</h4>
            <h6 className="login-page-subtitle">
              {t("WelcomeDandySubtitle")} <span>{t("reward")}</span>
            </h6>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={5}
            lg={6}
            className="d-flex align-items-center"
          >
            <img
              className="login-photo"
              src={require("../../imgs/Rewarding program.png")}
              alt="login-pic"
            />
          </Col>
          <Col xs={12} sm={12} md={7} lg={6}>
            <h4 className="login-form-title mb-3">{t("userLogin")}</h4>
            <Formik
              initialValues={{ mobileNumber: "", accountType: "" }}
              onSubmit={(values) => {
                const loadReq = toast.loading(t("wait"), {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });
                if (values.accountType === "owner") {
                  setAccountType("owner");
                  dispatch(
                    ownerLogin({
                      mobileNumber: values.mobileNumber,
                      merchantName: merchName,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      setMobile(res.meta.arg.mobileNumber);
                      setOtpModalShow(true);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data["message Ar"],
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.message,
                        res.payload.data["message Ar"],
                        loadReq
                      );
                    }
                  });
                } else if (values.accountType === "cashier") {
                  setAccountType("cashier");
                  dispatch(
                    cashierLogin({
                      mobileNumber: values.mobileNumber,
                      merchantName: merchName,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      setMobile(res.meta.arg.mobileNumber);
                      setOtpModalShow(true);
                      successMessage(
                        res.payload.data.message,
                        res.payload.data["message Ar"],
                        loadReq
                      );
                    } else {
                      failedMessage(
                        res.payload.data.message,
                        res.payload.data["message Ar"],
                        loadReq
                      );
                    }
                  });
                }
              }}
              validationSchema={loginValidation}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <Form className="px-md-5" onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      {!merchant && !cashier ? (
                        <Row className="my-2">
                          <Col xs={6}>
                            <input
                              hidden
                              type="radio"
                              id="cashier"
                              name="accountType"
                              className="account-input"
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setFieldValue("accountType", "cashier");
                                }
                              }}
                            />
                            <label
                              className="btn btn-light btn-sm account-btn"
                              htmlFor="cashier"
                            >
                              <FaUserAlt className="mx-2" />
                              {t("cashier")}
                            </label>
                          </Col>
                          <Col xs={6}>
                            <input
                              hidden
                              type="radio"
                              className="account-input"
                              id="owner"
                              name="accountType"
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  setFieldValue("accountType", "owner");
                                }
                              }}
                            />
                            <label
                              className="btn btn-light btn-sm account-btn"
                              htmlFor="owner"
                            >
                              <FaUserTie className="mx-2" />
                              {t("owner")}
                            </label>
                          </Col>
                          {errors.accountType && touched.accountType && (
                            <Form.Text className="text-error">
                              {t(errors.accountType)}
                            </Form.Text>
                          )}
                        </Row>
                      ) : merchant ? (
                        <Row className="my-2">
                          <Col
                            xs={12}
                            sm={{ span: 8, offset: 2 }}
                            md={{ span: 6, offset: 3 }}
                          >
                            <input
                              hidden
                              type="radio"
                              id="cashier"
                              name="accountType"
                              className="account-input"
                              defaultValue={(values.accountType = "cashier")}
                              defaultChecked={true}
                            />
                            <label
                              className="btn btn-light btn-sm account-btn"
                              htmlFor="cashier"
                            >
                              <FaUserAlt className="mx-2" />
                              Cashier
                            </label>
                          </Col>
                        </Row>
                      ) : cashier ? (
                        <Row className="my-2">
                          <Col
                            xs={12}
                            sm={{ span: 8, offset: 2 }}
                            md={{ span: 6, offset: 3 }}
                          >
                            <input
                              hidden
                              type="radio"
                              className="account-input"
                              id="owner"
                              name="accountType"
                              defaultValue={(values.accountType = "owner")}
                              defaultChecked={true}
                            />
                            <label
                              className="btn btn-light btn-sm account-btn"
                              htmlFor="owner"
                            >
                              <FaUserTie className="mx-2" />
                              Owner
                            </label>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                    <Col xs={12} className="mb-3">
                      <InputGroup className="login-input">
                        <InputGroup.Text className="login-input-icon">
                          <FaUser />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          maxLength={11}
                          className="login-input-field"
                          placeholder={t("mobileNumber")}
                          id="mobileNumber"
                          name="mobileNumber"
                          onChange={handleChange}
                          value={values.mobileNumber}
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </InputGroup>
                      {errors.mobileNumber && touched.mobileNumber && (
                        <Form.Text className="text-error">
                          {t(errors.mobileNumber)}
                        </Form.Text>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      md={{ span: 6, offset: 3 }}
                      className="d-grid mt-3"
                    >
                      <Button
                        variant="danger"
                        type="submit"
                        className="login-btn"
                        size="sm"
                      >
                        {t("login")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
          <Col xs={12} className="mt-5" dir="ltr">
            <span>Powered by:</span>
            <img
              className="poweredby-photo"
              src={require("../../imgs/Sharee Coin LOGO transparent white.png")}
              alt="shareecoin-logo"
            />
          </Col>
        </Row>
      </Container>
      <LoginOTP
        show={otpModalShow}
        onHide={() => {
          setOtpModalShow(false);
        }}
        mobilenumber={mobile}
        accounttype={accountType}
      />
    </div>
  );
}
