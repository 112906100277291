import React from "react";
import { Navigate } from "react-router-dom";

export default function VoucherPrivateRoute({ vouchs, children }) {
  if (vouchs.length === 0) {
    return <Navigate to="/customer/home" />;
  } else {
    return children;
  }
}
