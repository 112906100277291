import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import RedeemFirstStep from "./RedeemFirstStep";
import RedeemSecondStep from "./RedeemSecondStep";
import ChangeLang from "../../changeLang/ChangeLang";
import { FaHome } from "react-icons/fa";
import { IoReloadCircle } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./Redeem.css";
import "./Redeem.rtl.css";
import { handleResetRedeem } from "../../../redux/features/cashierSlice";

function Redeem() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  return (
    <div className="redeem py-3">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header py-2 pb-1 d-flex justify-content-start"
          >
            <ChangeLang />
            <Button
              variant="danger"
              size="sm"
              className="mx-2"
              onClick={() => {
                navigate("/cashier/home");
                window.location.reload();
              }}
            >
              <FaHome />
            </Button>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`tooltip-right`}>Reset Redeem e-Gift</Tooltip>
              }
            >
              <Button
                variant="success"
                size="sm"
                onClick={() => {
                  dispatch(handleResetRedeem());
                }}
              >
                <IoReloadCircle />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col xs={12} className="layout-body">
            <Row>
              <h4 className="redeem-title mb-2">{t("redeemEgift")}</h4>
              <Col xs={12} lg={6} className="mb-3">
                <RedeemFirstStep />
              </Col>
              <Col xs={12} lg={6}>
                <RedeemSecondStep />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2 pt-3">
            <img src={require("../../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Redeem;
