import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChangeLang from "../../changeLang/ChangeLang";
import "./CustomerHome.css";
import "./CustomerHome.rtl.css";

export default function CustomerHome() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="customer-home">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header d-flex justify-content-start py-3 pb-1"
          >
            <ChangeLang />
          </Col>
          <Col xs={12} className="layout-body pb-4">
            <Row>
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                className="order-1 order-md-1"
              >
                <img
                  src={require("../../../imgs/dandylogo.png")}
                  alt="dandy-logo"
                  className="dandy-logo"
                />
              </Col>
              <Col
                xs={{ span: 8, offset: 2 }}
                md={{ span: 4, offset: 1 }}
                className="d-flex justify-content-center align-items-center order-3 order-md-2"
              >
                <Button
                  variant="danger"
                  className="start-btn"
                  onClick={() => {
                    navigate("/customer/verify/receipt");
                  }}
                >
                  {t("chooseGift")}
                </Button>
              </Col>
              <Col
                xs={12}
                md={6}
                className="order-2 order-md-3"
              >
                <img
                  src={require("../../../imgs/cardd.png")}
                  alt="card-logo"
                  className="card-logo"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2">
            <img src={require("../../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
