import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { TbReportMoney } from "react-icons/tb";
import { RiUserAddFill, RiLogoutBoxFill } from "react-icons/ri";
import { FaInfoCircle, FaClipboardList } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { persistor } from "../../../index";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import ListCashiers from "../listCashiers/ListCashiers";
import ChangeLang from "../../changeLang/ChangeLang";
import { useTranslation } from "react-i18next";
import "./MHome.css";
import "./MHome.rtl.css";
import { handleOwnerLogout } from "../../../redux/features/ownerSlice";

export default function ManagerHome() {
  const navigate = useNavigate();
  const cat = useSelector((state) => state.reducer.owner.merchantInfo.category);
  const credit = useSelector(
    (state) => state.reducer.owner.merchantInfo.credit
  );
  const merchantName = useSelector(
    (state) => state.reducer.owner.merchantInfo.merchantName
  );
  const debit = useSelector((state) => state.reducer.owner.merchantInfo.debit);
  const logoImage = useSelector(
    (state) => state.reducer.owner.merchantInfo.logoImage
  );
  const vouchersGenerated = useSelector(
    (state) => state.reducer.owner.merchantInfo.vouchersGenerated
  );
  const vouchersRedeemed = useSelector(
    (state) => state.reducer.owner.merchantInfo.vouchersRedeemed
  );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{merchantName} INFO</Popover.Header>
      <Popover.Body>
        <p className="mb-1">
          Credit : <strong>{credit}</strong>
        </p>
        <p className="mb-1">
          Debit : <strong>{debit}</strong>
        </p>
        <p className="mb-1">
          Vouchers Generated : <strong>{vouchersGenerated}</strong>
        </p>
        <p className="mb-1">
          Vouchers Redeemed : <strong>{vouchersRedeemed}</strong>
        </p>
      </Popover.Body>
    </Popover>
  );
  const [listModalShow, setListModalShow] = useState(false);
  const { t, i18n } = useTranslation();

  const editMerchantName = (name) => {
    const merchantArr = name.split(" ");
    for (var i = 0; i < merchantArr.length; i++) {
      merchantArr[i] =
        merchantArr[i].charAt(0).toUpperCase() + merchantArr[i].substr(1);
    }
    const merchStr = merchantArr.join("_");
    return merchStr;
  };

  const merchantNameURL = editMerchantName(merchantName);
  const dispatch = useDispatch();

  return (
    <div className="manager-home py-3">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header d-flex justify-content-start py-2 pb-1"
          >
            <ChangeLang />
          </Col>
          <Col xs={12} className="layout-body py-3">
            <Row>
              <Col xs={12}>
                <img
                  src={logoImage}
                  alt="merchant-logoo"
                  className="owner-merchant-logo me-3 mb-3"
                />
                <div>
                  <div className="manager-home-title">
                    {t("Welcome")} - <strong>{merchantName}</strong>
                    <Button variant="light" size="sm" className="ms-3" disabled>
                      <FiEdit />
                    </Button>
                  </div>
                  <h6 className="manager-home-category">{cat}</h6>
                  <OverlayTrigger
                    trigger="click"
                    placement="auto-start"
                    overlay={popover}
                  >
                    <Button
                      size="sm"
                      variant="info"
                      className="merchant-info-btn mx-0"
                    >
                      <FaInfoCircle className="merchant-info-icon" />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col
                xs={12}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 3 }}
              >
                <Row>
                  <Col xs={12} className="d-grid mb-3">
                    <Button
                      variant="light"
                      size="lg"
                      className="add-cashier-btn"
                      onClick={() => {
                        navigate("/manager/add/cashier");
                      }}
                    >
                      <RiUserAddFill className="cashier-icon me-2" />
                      {t("addCashier")}
                    </Button>
                  </Col>
                  <Col xs={12} className="d-grid mb-3">
                    <Button
                      variant="light"
                      size="lg"
                      className="add-cashier-btn"
                      onClick={() => {
                        setListModalShow(true);
                      }}
                    >
                      <FaClipboardList className="cashier-icon me-2" />
                      {t("viewCashiers")}
                    </Button>
                  </Col>
                  <Col xs={12} className="d-grid">
                    <Button
                      variant="light"
                      size="lg"
                      className="add-cashier-btn"
                      onClick={() => {
                        navigate(`/eVoucher/${merchantNameURL}/login`);
                        dispatch(handleOwnerLogout());
                        window.location.reload();
                      }}
                    >
                      <RiLogoutBoxFill className="cashier-icon me-2" />
                      {t("logout")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2">
            <img src={require("../../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
        <ListCashiers
          show={listModalShow}
          onHide={() => {
            setListModalShow(false);
          }}
        />
      </Container>
    </div>
  );
}
