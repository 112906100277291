import React, { useState, useEffect } from "react";
import Quagga from "quagga";
import { AiOutlineReload } from "react-icons/ai";
import { handleScanBarcode } from "../../../redux/features/cashierSlice";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function Scanner(props) {
  const [modalShow, setModalShow] = useState(true);
  const dispatch = useDispatch();

  const handleScan = () => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: {
            facingMode: "environment",
          },
          area: {
            // defines rectangle of the detection/localization area
            // top: "10%", // top offset
            // right: "10%", // right offset
            // left: "10%", // left offset
            // bottom: "10%", // bottom offset
          },
        },
        locator: {
          halfSample: true,
          patchSize: "medium", // x-small, small, medium, large, x-large
          debug: {
            showCanvas: false,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: false,
              showTransformedBox: false,
              showBB: false,
            },
          },
        },
        numOfWorkers: 1,
        decoder: {
          readers: ["code_128_reader"],
          debug: {
            drawBoundingBox: true,
            showFrequency: true,
            drawScanline: true,
            showPattern: true,
          },
        },
        locate: true,
      },
      function (err) {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected((result) => {
      if (result) {
        dispatch(
          handleScanBarcode({
            barcode: result.codeResult.code,
          })
        );
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    handleScan();
  }, []);

  return <div id="interactive" className="viewport"></div>;
}
