import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createVoucher } from "../../../../redux/features/customerSlice";
import { toast, Zoom } from "react-toastify";
import { useTranslation } from "react-i18next";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useNavigate } from "react-router-dom";
import "./OtpForm.css";

export default function OtpForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileNumber = useSelector(
    (state) => state.customer.thirdStep.mobileNumber
  );
  const issuerID = useSelector((state) => state.customer.firstStep.IssuerID);
  const merchantID = useSelector(
    (state) => state.customer.secondStep.merchantID
  );
  const voucherValue = useSelector(
    (state) => state.customer.secondStep.voucherValue
  );

  const handleClickotp = (first, last) => {
    if (document.getElementById(first).value !== "") {
      document.getElementById(last).focus();
    }
  };

  return (
    <div className="otp-form">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dir="ltr"
      >
        <Modal.Body className="modal-body-otp">
          <Formik
            initialValues={{
              firstDigit: "",
              secondDigit: "",
              thirdDigit: "",
              fourthDigit: "",
              fifthDigit: "",
              sixthDigit: "",
            }}
            onSubmit={(values) => {
              var otpText = "";
              otpText += values.firstDigit;
              otpText += values.secondDigit;
              otpText += values.thirdDigit;
              otpText += values.fourthDigit;
              otpText += values.fifthDigit;
              otpText += values.sixthDigit;
              dispatch(
                createVoucher({
                  otp: otpText,
                  mobileNumber,
                  issuerID,
                  merchantID,
                  voucherValue,
                })
              ).then((res) => {
                const loadReq = toast.loading(t("wait"), {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });
                if (res.payload.data.statusCode === 200) {
                  successMessage(
                    res.payload.data.message,
                    res.payload.data["messageAr"],
                    loadReq
                  );
                  navigate("/customer/gift/congrats");
                } else {
                  failedMessage(
                    res.payload.data.message,
                    res.payload.data["messageAr"],
                    loadReq
                  );
                }
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form className="px-2" onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3 otp-group px-md-2 py-3">
                  <Col xs={12}>
                    <h6 className="note-otp text-center">
                      {t("OTPGiftVouch")}
                    </h6>
                    <div className="otp-group-list px-3 px-md-4 py-3" dir="ltr">
                      <Row>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="firstDigit"
                            id="firstDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("firstDigit", "secondDigit");
                            }}
                            onChange={handleChange}
                            value={values.firstDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="secondDigit"
                            id="secondDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("secondDigit", "thirdDigit");
                            }}
                            onChange={handleChange}
                            value={values.secondDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="thirdDigit"
                            id="thirdDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("thirdDigit", "fourthDigit");
                            }}
                            onChange={handleChange}
                            value={values.thirdDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="fourthDigit"
                            id="fourthDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("fourthDigit", "fifthDigit");
                            }}
                            onChange={handleChange}
                            value={values.fourthDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="fifthDigit"
                            id="fifthDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("fifthDigit", "sixthDigit");
                            }}
                            onChange={handleChange}
                            value={values.fifthDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="sixthDigit"
                            id="sixthDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onChange={handleChange}
                            value={values.sixthDigit}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Form.Group>
                <Row className="justify-content-center">
                  <Col xs={12} md={6}>
                    <Button
                      variant="danger"
                      className="start-btn"
                      type="submit"
                    >
                      {t("sendGift")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
