import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { FaUserAlt, FaMobileAlt } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import OtpForm from "./OtpForm";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { sendOTP } from "../../../../redux/features/customerSlice";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import ChangeLang from "../../../changeLang/ChangeLang";
import "./ThirdStep.css";
import "./ThirdStep.rtl.css";

export default function ThirdStep() {
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const sendOtpValidation = yup.object().shape({
    firstName: yup
      .string()
      .required("firstNameReq")
      .matches(
        /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()\[\]<>{}])[\S])+$/gm,
        "firstNameMiss"
      ),
    lastName: yup
      .string()
      .required("lastNameReq")
      .matches(
        /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()\[\]<>{}])[\S])+$/gm,
        "lastNameMiss"
      ),
    mobileNumber: yup
      .string()
      .required("mobilereq")
      .matches(/^01[0125][0-9]{8}$/gm, "mobilenotValid"),
    gender: yup.string().required("genderReq"),
  });

  const dispatch = useDispatch();
  return (
    <div className="customer-third-step">
      <div className="third-step-content w-100">
        <Container>
          <Row>
            <Col xs={12} className="layout-header py-3 pb-1">
              <ChangeLang />
            </Col>
            <Col xs={12} className="layout-body py-3">
              <Row>
                <Col xs={12} lg={3}>
                  <img
                    src={require("../../../../imgs/welcomedandy.png")}
                    alt="welcome-dandy-logo"
                    className="welcome-dandy-logo"
                  />
                </Col>
                <Col xs={12} lg={9}>
                  <Row>
                    <Col xs={12}>
                      <div className="third-step-form py-4 px-3">
                        <Formik
                          initialValues={{
                            firstName: "",
                            lastName: "",
                            mobileNumber: "",
                            gender: "",
                          }}
                          onSubmit={(values) => {
                            const loadReq = toast.loading(
                              t("wait"),
                              {
                                transition: Zoom,
                                position:
                                  i18n.language === "ar"
                                    ? "bottom-right"
                                    : "bottom-left",
                              }
                            );
                            dispatch(
                              sendOTP({
                                firstName: values.firstName,
                                lastName: values.lastName,
                                mobileNumber: values.mobileNumber,
                                gender: values.gender,
                              })
                            ).then((res) => {
                              if (res.payload.data.statusCode === 200) {
                                successMessage(
                                  res.payload.data.message,
                                  res.payload.data["message Ar"],
                                  loadReq
                                );
                                setShowModal(true);
                              } else {
                                failedMessage(
                                  res.payload.data.message,
                                  res.payload.data["message Ar"],
                                  loadReq
                                );
                              }
                            });
                          }}
                          validationSchema={sendOtpValidation}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                          }) => (
                            <Form className="px-4" onSubmit={handleSubmit}>
                              <Form.Group
                                as={Row}
                                className="mb-3 third-step-group px-3 py-3"
                              >
                                <Form.Label column xs={12} md={3}>
                                  <FaUserAlt className="mx-2" />
                                  {t("yourName")}:
                                </Form.Label>
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  className="mb-2 mb-md-0"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder={t("firstName")}
                                    className="third-step-input"
                                    name="firstName"
                                    id="firstName"
                                    onChange={handleChange}
                                    value={values.firstName}
                                  />
                                  {errors.firstName && touched.firstName && (
                                    <Form.Text className="text-error">
                                      {t(errors.firstName)}
                                    </Form.Text>
                                  )}
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                  <Form.Control
                                    type="text"
                                    placeholder={t("lastName")}
                                    className="third-step-input"
                                    name="lastName"
                                    id="lastName"
                                    onChange={handleChange}
                                    value={values.lastName}
                                  />
                                  {errors.lastName && touched.lastName && (
                                    <Form.Text className="text-error">
                                      {t(errors.lastName)}
                                    </Form.Text>
                                  )}
                                </Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-3 third-step-group px-3 py-3"
                              >
                                <Form.Label column xs={12} md={4}>
                                  <BsGenderAmbiguous className="mx-2" />
                                  {t("Gender")}:
                                </Form.Label>
                                <Col xs={12} md={{ span: 6, offset: 1 }}>
                                  <Row>
                                    <Col
                                      xs={12}
                                      sm={6}
                                      className="mb-3 mb-sm-0"
                                    >
                                      <input
                                        hidden
                                        className="gender-input"
                                        type="radio"
                                        name="gender"
                                        id="male"
                                        onChange={(e) => {
                                          if (e.target.checked === true) {
                                            setFieldValue("gender", "male");
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="male"
                                        className="btn btn-sm btn-dark gender-radio-btn"
                                      >
                                        <img
                                          src={require("../../../../imgs/Male.png")}
                                          alt="male-pic"
                                          className="male-icon"
                                        />
                                        <span className="mx-2">
                                          {t("male")}
                                        </span>
                                      </label>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <input
                                        hidden
                                        className="gender-input"
                                        type="radio"
                                        name="gender"
                                        id="female"
                                        onChange={(e) => {
                                          if (e.target.checked === true) {
                                            setFieldValue("gender", "female");
                                          }
                                        }}
                                      />
                                      <label
                                        htmlFor="female"
                                        className="btn btn-sm btn-dark gender-radio-btn"
                                      >
                                        <img
                                          src={require("../../../../imgs/Female.png")}
                                          alt="female-pic"
                                          className="female-icon"
                                        />
                                        <span className="mx-2">
                                          {t("female")}
                                        </span>
                                      </label>
                                    </Col>
                                    {errors.gender && touched.gender && (
                                      <Form.Text className="text-error">
                                        {t(errors.gender)}
                                      </Form.Text>
                                    )}
                                  </Row>
                                </Col>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-3 third-step-group px-3 py-3"
                              >
                                <Form.Label column xs={12} md={4}>
                                  <FaMobileAlt className="mx-2" />
                                  {t("yourMobile")}:
                                </Form.Label>
                                <Col xs={12} md={4}>
                                  <Form.Control
                                    type="text"
                                    maxLength={11}
                                    placeholder={t("mobileNumberEn")}
                                    className="third-step-input third-step-mobile-input"
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    onChange={handleChange}
                                    value={values.mobileNumber}
                                    onKeyPress={(e) => {
                                      var char = String.fromCharCode(e.which);
                                      if (!/[0-9]/.test(char)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  {errors.mobileNumber &&
                                    touched.mobileNumber && (
                                      <Form.Text className="text-error">
                                        {t(errors.mobileNumber)}
                                      </Form.Text>
                                    )}
                                </Col>
                              </Form.Group>
                              <Row className="justify-content-center">
                                <Col xs={12} md={6}>
                                  <Button
                                    variant="danger"
                                    className="third-start-btn"
                                    type="submit"
                                  >
                                    {t("Ok")}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="layout-footer footer pb-2">
              <img
                src={require("../../../../imgs/Credit.png")}
                alt="footer-pic"
              />
            </Col>
          </Row>
        </Container>
        <OtpForm
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        />
      </div>
    </div>
  );
}
