import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { TbReportMoney } from "react-icons/tb";
import { RiLogoutBoxFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChangeLang from "../../changeLang/ChangeLang";
import { useTranslation } from "react-i18next";
import { handleCashierLogout } from "../../../redux/features/cashierSlice";
import "./CashierHome.css";
import "./CashierHome.rtl.css";

export default function CashierHome() {
  const navigate = useNavigate();
  const merchantName = useSelector(
    (state) => state.reducer.cashier.login.merchantName
  );

  const logoMerchant = useSelector(
    (state) => state.reducer.cashier.login.merchantLogo
  );
  const editMerchantName = (name) => {
    const merchantArr = name.split(" ");
    for (var i = 0; i < merchantArr.length; i++) {
      merchantArr[i] =
        merchantArr[i].charAt(0).toUpperCase() + merchantArr[i].substr(1);
    }
    const merchStr = merchantArr.join("_");
    return merchStr;
  };
  const dispatch = useDispatch();

  const merchantNameURL = editMerchantName(merchantName);

  const { t, i18n } = useTranslation();
  return (
    <div className="cashier-home py-3">
      <Container>
        <Row>
          <Col
            xs={12}
            className="layout-header d-flex justify-content-start py-2 pb-1"
          >
            <ChangeLang />
          </Col>
          <Col xs={12} className="layout-body py-3">
            <Row className="cashier-home-content">
              <Col xs={12} md={12}>
                <Row>
                  <Col
                    xs={12}
                    md={4}
                    className="d-flex justify-content-md-end justify-content-center"
                  >
                    <div className="me-3 mb-3">
                      <img
                        src={logoMerchant}
                        alt="cashier-merchant-logo"
                        className="cashier-merchant-logo me-3 mb-3"
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={8}
                    className="d-flex justify-content-md-start"
                  >
                    <div className="cashier-merchant-data">
                      <div className="cashier-home-title">
                        {t("Welcome")} ( {merchantName} )
                        <Button
                          variant="light"
                          size="sm"
                          className="ms-3"
                          disabled
                        >
                          <FiEdit />
                        </Button>
                      </div>
                      <h6 className="cashier-home-category">Category</h6>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={8}>
                <div
                  className="cashier-redeem-content"
                  onClick={() => {
                    navigate("/cashier/redeem");
                  }}
                >
                  <img
                    src={require("../../../imgs/redeem.png")}
                    alt="redeem-pic"
                  />
                  <h5 className="mt-3">{t("redeemEgift")}</h5>
                </div>
              </Col>
              <Col xs={12} lg={4}>
                <Row>
                  <Col xs={12} className="d-grid mb-3 mt-2 mt-lg-0">
                    <Link
                      to="/cashier/report"
                      className="d-grid text-decoration-none"
                    >
                      <Button variant="light" size="lg" className="report-btn">
                        <TbReportMoney className="report-icon me-2" />
                        {t("reports")}
                      </Button>
                    </Link>
                  </Col>
                  <Col xs={12} className="d-grid">
                    <Button
                      variant="light"
                      size="lg"
                      className="report-btn"
                      onClick={() => {
                        navigate(`/eVoucher/${merchantNameURL}/login`);
                        dispatch(handleCashierLogout());
                        window.location.reload();
                      }}
                    >
                      <RiLogoutBoxFill className="report-icon me-2" />
                      {t("logout")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="layout-footer footer pb-2">
            <img src={require("../../../imgs/Credit.png")} alt="footer-pic" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
