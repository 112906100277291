import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ownerOtpSubmit } from "../../redux/features/ownerSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  cashierOtpSubmit,
  handleCashierLogout,
} from "../../redux/features/cashierSlice";
import failedMessage from "../messages/Failed";
import successMessage from "../messages/Successful";
import { toast } from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";
import "./LoginOTP.css";

export default function LoginOTP(props) {
  const merchantName = useParams("merchant");
  const [merchName, setMerchName] = useState("");
  const mobileNumber = props.mobilenumber;
  const accountType = props.accounttype;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const handleClickotp = (first, last) => {
    if (document.getElementById(first).value !== "") {
      document.getElementById(last).focus();
    }
  };

  const editMerchantName = (name) => {
    const merchantArr = name.split("_");
    for (var i = 0; i < merchantArr.length; i++) {
      merchantArr[i] =
        merchantArr[i].charAt(0).toUpperCase() + merchantArr[i].substr(1);
    }
    const merchStr = merchantArr.join(" ");
    return merchStr;
  };

  useEffect(() => {
    const merchantNamee = merchantName.merchant;
    const newMerchantNAme = editMerchantName(merchantNamee);
    setMerchName(newMerchantNAme);
  }, []);


  return (
    <div className="login-otp">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dir="ltr"
      >
        <Modal.Body className="modal-body-otp">
          <Formik
            initialValues={{
              firstDigit: "",
              secondDigit: "",
              thirdDigit: "",
              fourthDigit: "",
              fifthDigit: "",
              sixthDigit: "",
            }}
            onSubmit={(values) => {
              var otpText = "";
              otpText += values.firstDigit;
              otpText += values.secondDigit;
              otpText += values.thirdDigit;
              otpText += values.fourthDigit;
              otpText += values.fifthDigit;
              otpText += values.sixthDigit;
              const loadReq = toast.loading(t("wait"), {
                transition: Zoom,
                position:
                  i18n.language === "ar" ? "bottom-right" : "bottom-left",
              });
              if (accountType === "owner") {
                dispatch(
                  ownerOtpSubmit({
                    mobileNumber,
                    merchantName: merchName,
                    otp: otpText,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    successMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                    navigate("/manager/home");
                  } else {
                    failedMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                  }
                });
              } else if (accountType === "cashier") {
                dispatch(
                  cashierOtpSubmit({
                    mobileNumber,
                    merchantName,
                    otp: otpText,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    successMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                    navigate("/cashier/home");
                  } else {
                    failedMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                  }
                });
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form className="px-2" onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className="mb-3 login-otp-group px-md-2 py-3"
                >
                  <Col xs={12} md={{ span: 10, offset: 1 }}>
                    <h6 className="note-otp text-center">
                      {t("OTPloginVouch")}
                    </h6>
                    <div className="login-otp-group-list px-3 px-md-4 py-3">
                      <Row>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center login-otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="firstDigit"
                            id="firstDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("firstDigit", "secondDigit");
                            }}
                            onChange={handleChange}
                            value={values.firstDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center login-otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="secondDigit"
                            id="secondDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("secondDigit", "thirdDigit");
                            }}
                            onChange={handleChange}
                            value={values.secondDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center login-otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="thirdDigit"
                            id="thirdDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("thirdDigit", "fourthDigit");
                            }}
                            onChange={handleChange}
                            value={values.thirdDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center login-otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="fourthDigit"
                            id="fourthDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("fourthDigit", "fifthDigit");
                            }}
                            onChange={handleChange}
                            value={values.fourthDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center login-otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="fifthDigit"
                            id="fifthDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onKeyUp={() => {
                              handleClickotp("fifthDigit", "sixthDigit");
                            }}
                            onChange={handleChange}
                            value={values.fifthDigit}
                          />
                        </Col>
                        <Col className="px-1">
                          <Form.Control
                            className="text-center login-otp-input"
                            type="text"
                            maxLength={1}
                            min={1}
                            max={9}
                            placeholder=""
                            size="sm"
                            name="sixthDigit"
                            id="sixthDigit"
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                            }}
                            onChange={handleChange}
                            value={values.sixthDigit}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Form.Group>
                <Row className="justify-content-center">
                  <Col xs={12} md={6} className="d-grid">
                    <Button
                      variant="danger"
                      className="login-start-btn"
                      type="submit"
                    >
                      {t("submit")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
