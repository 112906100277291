import React from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { IoPersonRemoveSharp, IoPersonAddSharp } from "react-icons/io5";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  activeCashier,
  deactiveCashier,
  deleteCashier,
} from "../../../redux/features/ownerSlice";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import "./ListCashiers.css";
import "./ListCashiers.rtl.css";

export default function ListCashiers(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columnDefs = [
    {
      headerName: "First Name",
      field: "First Name",
    },
    {
      headerName: "Last Name",
      field: "Last Name",
    },
    {
      headerName: "Status",
      field: "Cashier Status",
      cellRenderer: (res) => {
        if (res.data["Cashier Status"] === "True") {
          return (
            <Badge
              className="activate-cashier"
              bg="success"
              onClick={() => {
                dispatch(
                  deactiveCashier({
                    mobileNumber: res.data["Cashier Mobilenumber"],
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    toast(
                      i18n.language === "ar"
                        ? res.payload.data["message Ar"]
                        : res.payload.data.message,
                      {
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                        transition: Flip,
                        closeButton: false,
                        autoClose: 6000,
                        hideProgressBar: true,
                      }
                    );
                  }
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }}
            >
              {t("active")}
            </Badge>
          );
        } else if (res.data["Cashier Status"] === "False") {
          return (
            <Badge
              className="deactivate-cashier"
              bg="dark"
              onClick={() => {
                dispatch(
                  activeCashier({
                    mobileNumber: res.data["Cashier Mobilenumber"],
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    toast(
                      i18n.language === "ar"
                        ? res.payload.data["message Ar"]
                        : res.payload.data.message,
                      {
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                        transition: Flip,
                        closeButton: false,
                        autoClose: 6000,
                        hideProgressBar: true,
                      }
                    );
                  }
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }}
            >
              {t("notActive")}
            </Badge>
          );
        }
      },
    },
    {
      headerName: "Mobile Number",
      field: "Cashier Mobilenumber",
    },
    {
      headerName: "Governorate",
      field: "governorate_name_en",
    },
    {
      headerName: "Action",
      field: "CashierID",
      cellRenderer: (res) => {
        return (
          <div>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                dispatch(
                  deleteCashier({
                    mobileNumber: res.data["Cashier Mobilenumber"],
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    toast(
                      i18n.language === "ar"
                        ? res.payload.data["message Ar"]
                        : res.payload.data.message,
                      {
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                        transition: Flip,
                        closeButton: false,
                        autoClose: 6000,
                        hideProgressBar: true,
                      }
                    );
                  }
                });
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              }}
            >
              <IoPersonRemoveSharp className="me-2" />
              Remove Cashier
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultColDef = {
    editable: false,
    filter: true,
    floatingFilter: false,
    minWidth: 100,
  };

  const onGridReady = (params) => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        "Vendor Name": "GAD",
        Function: "ListCashiers",
      })
      .then((res) =>
        params.api.applyTransaction({ add: res.data["Cashiers' List"] })
      );
  };

  return (
    <div className="list-cashiers">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal-body-list-cashiers">
          <div className="ag-theme-alpine list-cashiers-table">
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              pagination={true}
              paginationAutoPageSize={true}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
