import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Badge,
  Alert,
} from "react-bootstrap";
import {
  MdOutlineMonetizationOn,
  MdOutlineMobileFriendly,
} from "react-icons/md";
import { MdAddAPhoto, MdOutlineInsertPhoto } from "react-icons/md";
import { IoReceiptOutline } from "react-icons/io5";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import CaprtureReceipt from "./CaprtureReceipt";
import { useDispatch, useSelector } from "react-redux";
import { verifyReceipt } from "../../../../redux/features/customerSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChangeLang from "../../../changeLang/ChangeLang";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import "./FirstStep.css";
import "./FirstStep.rtl.css";

export default function FirstStep() {
  const [show, setShow] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const croppedImage = useSelector(
    (state) => state.customer.firstStep.receiptCapturedImage
  );
  const receiptFile = useSelector(
    (state) => state.customer.firstStep.ReceiptFileImage
  );

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/gwq4wy0e45qy599rar7quiycalxvear7", {
        Function: "Get Issuers",
      })
      .then((res) => {
        setMerchants(res.data["Items List"]);
      });
  }, []);

  const verifyReceiptValidation = yup.object().shape({
    issuerID: yup.string().required("shopReq"),
    receiptValue: yup.string().required("receiptValReq"),
    imageFile: yup.string().required("receiptFile"),
  });

  const convertNumbers2English = (string) => {
    return string
      .replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function (c) {
        return c.charCodeAt(0) - 0x06f0;
      });
  };
  return (
    <div className="customer-first-step d-flex justify-content-center align-items-center py-2">
      <div className="first-step-content">
        <Container>
          <Row>
            <Col xs={12} className="layout-header py-3 pb-1">
              <ChangeLang />
            </Col>
            <Col xs={12} className="layout-body py-3">
              <Row>
                <Col xs={12} lg={3} className="d-flex align-items-center">
                  <img
                    src={require("../../../../imgs/welcomedandy.png")}
                    alt="welcome-dandy-logo"
                    className="welcome-dandy-logo"
                  />
                </Col>
                <Col xs={12} lg={9}>
                  <div className="first-step-form py-4 px-3">
                    <Formik
                      initialValues={{
                        issuerID: "",
                        receiptValue: "",
                        imageFile: "",
                      }}
                      onSubmit={(values) => {
                        const newReceiptvalue = convertNumbers2English(
                          values.receiptValue
                        );
                        const loadReq = toast.loading(t("validateReceipt"), {
                          transition: Zoom,
                          position:
                            i18n.language === "ar"
                              ? "bottom-right"
                              : "bottom-left",
                        });
                        dispatch(
                          verifyReceipt({
                            issuerID: values.issuerID,
                            receiptValue: newReceiptvalue,
                            receiptFile,
                          })
                        )
                          .unwrap()
                          .then((res) => {
                            if (res.data.statusCode === 200) {
                              successMessage(
                                res.data.message,
                                res.data["messageAr"],
                                loadReq
                              );
                              navigate("/customer/choose/voucher");
                            } else {
                              failedMessage(
                                res.data.message,
                                res.data["messageAr"],
                                loadReq
                              );
                            }
                          });
                      }}
                      validationSchema={verifyReceiptValidation}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                      }) => (
                        <Form className="px-4" onSubmit={handleSubmit}>
                          <Form.Group
                            as={Row}
                            className="mb-3 first-step-group px-3 py-3"
                          >
                            <Form.Label column xs={12} md={7}>
                              <IoReceiptOutline className="mx-1" />
                              {t("shopReceipt")}:
                            </Form.Label>
                            <Col xs={12} md={5}>
                              <Form.Select
                                className="input-first-step input-first-step-font"
                                name="issuerID"
                                id="issuerID"
                                onChange={handleChange}
                                value={values.issuerID}
                              >
                                <option value="">{t("selectShop")}</option>
                                {merchants.map((merch) => {
                                  return (
                                    <option value={merch.ID} key={merch.ID}>
                                      {merch.Name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Col>
                            {errors.issuerID && touched.issuerID && (
                              <Form.Text className="text-error">
                                {t(errors.issuerID)}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3 first-step-group px-3 py-3"
                          >
                            <Form.Label column xs={12} md={4}>
                              <MdOutlineMonetizationOn className="mx-1" />
                              {t("howMuch")}:
                            </Form.Label>
                            <Col xs={12} md={3}>
                              <Form.Control
                                type="text"
                                placeholder="0"
                                className="input-first-step first-step-price"
                                name="receiptValue"
                                id="receiptValue"
                                onChange={handleChange}
                                value={values.receiptValue}
                                onKeyPress={(e) => {
                                  var char = String.fromCharCode(e.which);
                                  if (!/[0-9]/.test(char)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Col>
                            <Col xs={12} md={5}>
                              <Form.Text className="first-step-text">
                                {t("egp")}
                                <span className="mx-3">({t("coins")})</span>
                              </Form.Text>
                            </Col>
                            {errors.receiptValue && touched.receiptValue && (
                              <Form.Text className="text-error">
                                {t(errors.receiptValue)}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="mb-3 first-step-group px-3 py-3"
                          >
                            <Col xs={12} md={7}>
                              <Row>
                                <Form.Label column xs={12} md={5}>
                                  <MdOutlineMobileFriendly className="mx-1" />
                                  {t("receipt")} :
                                </Form.Label>
                                <Col xs={12} md={7}>
                                  <input
                                    hidden
                                    type="text"
                                    name="imageFile"
                                    id="imageFile"
                                    onChange={handleChange}
                                    value={
                                      receiptFile
                                        ? (values.imageFile = "ok")
                                        : (values.imageFile = "")
                                    }
                                  />
                                  <label
                                    className="capture-pic pt-2"
                                    onClick={() => {
                                      setShow(true);
                                    }}
                                  >
                                    <Badge bg="success">
                                      {t("captureReceipt")}
                                      <MdAddAPhoto className="mx-2" />
                                    </Badge>
                                  </label>
                                </Col>
                                <Col xs={12} className="mt-3">
                                  <Alert
                                    variant="danger"
                                    className="receipt-note"
                                  >
                                    {t("receiptNote")}
                                  </Alert>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} md={5}>
                              {croppedImage ? (
                                <img
                                  src={croppedImage}
                                  alt="receipt-pic"
                                  className="img-preview receipt-pic text-center py-2 px-3"
                                />
                              ) : (
                                <div className="receipt-pic">
                                  <MdOutlineInsertPhoto className="image-icon" />
                                </div>
                              )}
                            </Col>
                            {errors.imageFile && receiptFile === null && (
                              <Form.Text className="text-error">
                                {t(errors.imageFile)}
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Row>
                            <Col xs={12}>
                              <Button
                                variant="danger"
                                className="first-start-btn"
                                type="submit"
                              >
                                {t("Next")}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="layout-footer footer pb-2">
              <img
                src={require("../../../../imgs/Credit.png")}
                alt="footer-pic"
              />
            </Col>
          </Row>
        </Container>
        <CaprtureReceipt
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      </div>
    </div>
  );
}
