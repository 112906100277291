import { configureStore } from "@reduxjs/toolkit";
import ownerReducer from "./features/ownerSlice";
import cashierReducer from "./features/cashierSlice.js";
import customerReducer from "./features/customerSlice";
import storage from "redux-persist/lib/storage";
import {persistReducer} from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "dandy",
  storage,
};

const dandyReducer = combineReducers({
  owner: ownerReducer,
  cashier : cashierReducer
});

const persistedReducer = persistReducer(persistConfig, dandyReducer);

export const store = configureStore({
  reducer: {
    reducer : persistedReducer,
    customer: customerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
