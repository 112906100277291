import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FiGift } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { handleChooseVoucher } from "../../../../redux/features/customerSlice";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChangeLang from "../../../changeLang/ChangeLang";
import "./SecondStep.css";
import "./SecondStep.rtl.css";

export default function SecondStep() {
  const merchantId = useSelector(
    (state) => state.customer.secondStep.merchantID
  );
  const merchantName = useSelector(
    (state) => state.customer.secondStep.merchantName
  );
  const voucherValue = useSelector(
    (state) => state.customer.secondStep.voucherValue
  );
  const vouchers = useSelector((state) => state.customer.firstStep.vouchers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  });

  return (
    <div className="customer-second-step d-flex justify-content-center align-items-center py-2">
      <div className="second-step-content">
        <Container>
          <Row>
            <Col xs={12} className="layout-header py-3 pb-1">
              <ChangeLang />
            </Col>
            <Col xs={12} className="layout-body py-3">
              <Container>
                <Row>
                  <Col xs={12} lg={3} className="d-flex align-items-center">
                    <img
                      src={require("../../../../imgs/welcomedandy.png")}
                      alt="welcome-dandy-logo"
                      className="welcome-dandy-logo"
                    />
                  </Col>
                  <Col xs={12} lg={9} className="items-parent px-3 py-3">
                    <div className="second-step-items px-2 py-3">
                      <div>
                        <Form.Label className="second-step-label mb-0">
                          {t("secondStepfirst")} :
                        </Form.Label>
                        <Form.Text className="d-block second-step-text mt-0 mb-3">
                          {t("secondStepSecond")}
                        </Form.Text>
                      </div>
                      <div dir="ltr">
                        <Formik
                          initialValues={{
                            merchant: "",
                            voucherValue: "",
                            merchantID: "",
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form>
                              <Container>
                                <Row className="pick-items justify-content-center px-1 py-4 mx-0">
                                  {vouchers.length > 0 &&
                                    vouchers.map((vouch) => {
                                      return (
                                        <Col
                                          xs={2}
                                          className="mb-3 item-element-content"
                                          key={vouch.MerchantID}
                                        >
                                          <input
                                            hidden
                                            type="radio"
                                            className="vouch-input"
                                            checked={
                                              merchantId === vouch.MerchantID &&
                                              true
                                            }
                                            name="giftMerchant"
                                            id={vouch["Merchant Name"]}
                                            onChange={(e) => {
                                              dispatch(
                                                handleChooseVoucher({
                                                  merchantName: e.target.id,
                                                  merchantID: vouch.MerchantID,
                                                  voucherValue:
                                                    vouch["eGift Card Value"],
                                                })
                                              );
                                              toast(
                                                `${e.target.id} - ${vouch["eGift Card Value"]} EGP`,
                                                {
                                                  position:
                                                    i18n.language === "ar"
                                                      ? "bottom-right"
                                                      : "bottom-left",
                                                  transition: Flip,
                                                  closeButton: false,
                                                  autoClose: 6000,
                                                  hideProgressBar: true,
                                                }
                                              );
                                              setTimeout(() => {
                                                navigate("/customer/info");
                                              }, 1000);
                                            }}
                                          />
                                          <label
                                            htmlFor={vouch["Merchant Name"]}
                                            className="item-element px-3 py-3"
                                          >
                                            <img
                                              src={
                                                vouch["Merchant Logo ImageURL"]
                                              }
                                              alt="vouch-logo"
                                              className="item-pic mb-1"
                                            />{" "}
                                            <div className="item-info">
                                              <h5>{vouch["Merchant Name"]}</h5>
                                              <h6>{vouch.Category}</h6>
                                            </div>
                                            <div className="item-price py-1">
                                              <FiGift className="me-2 gift-icon" />
                                              <span className="mx-1">
                                                {vouch["eGift Card Value"]}
                                              </span>
                                              <span className="ft-small">
                                                EGP
                                              </span>
                                            </div>
                                          </label>
                                        </Col>
                                      );
                                    })}
                                </Row>
                              </Container>

                              <Form.Text className="text-error mt-2">
                                {errors.merchant &&
                                  touched.merchant &&
                                  errors.merchant}
                              </Form.Text>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={12} className="layout-footer footer pb-2">
              <img
                src={require("../../../../imgs/Credit.png")}
                alt="footer-pic"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
