import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./Congrats.css";

export default function Congrats() {

  const voucherLink = useSelector((state)=>state.customer.gift.voucherLink);
  return (
    <div className="congrats py-3">
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <img
              src={require("../../../../imgs/gift.png")}
              alt="congrats-gift"
              className="gift-image img-fluid animate__animated animate__swing animate__infinite"
            />
          </Col>
          <Col xs={12} className="text-center">
            <h5 className="congrats-title">مبروك الهدية</h5>
            <h6 className="congrats-subtitle">
              هايجيلك رسالة فيها لينك كود الهدية تروح المحل و تصرفها
            </h6>
            <h5 className="congrats-thanks py-4 px-2">شكراً لتسوقك فى داندي مول</h5>
            <p className="voucher-link">
              اضغط على لينك كود الهدية
              <NavLink to={{pathname: `//${voucherLink}`}} className="click-here mx-1" target="_blank"> من هنا</NavLink>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
