import React from "react";
import { Navigate } from "react-router-dom";

export default function OwnerPrivateRoute({ merchant, children }) {
    if(!merchant){
        return (
            <Navigate to="/login/lost"/>
        )
    }else{
        return children
    }
}
