import React, { useState } from "react";
import { Modal, Button, Row, Col, Container, Form } from "react-bootstrap";
import { FiCrop } from "react-icons/fi";
import { BsCameraFill, BsCamera } from "react-icons/bs";
import { RiCloseFill, RiImageAddFill } from "react-icons/ri";
import { TbCameraPlus } from "react-icons/tb";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import defaultSrc from "../../../../imgs/cropex.jpg";
import { useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCaptureReceipt,
  handleCropImage,
} from "../../../../redux/features/customerSlice";
import { useTranslation } from "react-i18next";
import ReceiptCapture from "./ReceiptCapture";
import { FaReceipt } from "react-icons/fa";
import { handleResetCapturedImage } from "../../../../redux/features/customerSlice";
import "./Capture.css";
import "./Capture.rtl.css";

export default function CaprtureReceipt(props) {
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState("");
  const [degree, setDegree] = useState(0);
  const { t, i18n } = useTranslation();
  const capturedImage = useSelector(
    (state) => state.customer.firstStep.receiptCapturedImage
  );

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="capture-receipt"
      >
        <Modal.Body className="px-4 pt-3 pb-0">
          <RiCloseFill
            className="close-icon mb-2"
            onClick={() => {
              props.onHide();
            }}
          />
          {image === "" ? (
            <div className="emptyField">
              <FaReceipt />
            </div>
          ) : (
            <img src={image} alt="receipt-pic" className="receipt-photo" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs={12} md={12} className="d-grid px-2 mb-2">
                <label
                  htmlFor="crop-image"
                  className="btn btn-sm btn-dark upload-btn"
                >
                  <RiImageAddFill className="mx-1" />
                  {t("capture")}
                </label>
                <input
                  hidden
                  id="crop-image"
                  type="file"
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} className="d-grid px-2">
                {image !== "" && (
                  <Button
                    variant="success"
                    size="sm"
                    className="crop-btn"
                    onClick={() => {
                      const imageFile = dataURLtoFile(image, "final-image");
                      dispatch(
                        handleCaptureReceipt({
                          capturedImage: image,
                          cropData: imageFile,
                        })
                      );
                      props.onHide();
                    }}
                  >
                    <IoCheckmarkDoneSharp className="mx-1" />
                    {t("done")}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
