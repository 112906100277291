import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import i18n from "i18next";

export const cashierLogin = createAsyncThunk(
  "cashier/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Cashier MobileMobileNumber": data.mobileNumber,
          "Vendor Name": data.merchantName,
          Function: "CashierSendOTP",
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const cashierOtpSubmit = createAsyncThunk(
  "cashier/otp",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "Cashier MobileMobileNumber": data.mobileNumber,
          "OTP Code": data.otp,
          Function: "CashierLogin",
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const sendOTPRedeem = createAsyncThunk(
  "cashier/redeem/otp",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          "eVoucher code": data.barcode,
          "Acquirer Name": data.merchantName,
          Function: "Send OTP",
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const burnVouch = createAsyncThunk(
  "cashier/burn/vouch",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7",
        {
          OTP: data.redeemOTP,
          "eVoucher code": data.vouchCode,
          CashierID: data.cashierID,
          Function: "BurnVoucher",
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  login: {
    type: "",
    mobileNumber: "",
    cashierID: "",
    message: "",
    messageAr: "",
    merchantName: "",
    merchantLogo: "",
    startTimeOut: false,
  },
  scanBarcode: {
    barcode: "",
    barcodeModalShow: true,
    active: true,
  },
  burnCode: {
    voucherValue: "",
    message: "",
    messageAr: "",
    active: false,
    minutes: "00",
    seconds: "00",
  },
};
export const cashierSlice = createSlice({
  name: "cashier",
  initialState,
  extraReducers: {
    [cashierLogin.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.login.mobileNumber = action.meta.arg.mobileNumber;
        state.login.merchantName = action.payload.data["Merchant Name"];
        state.login.merchantLogo = action.payload.data["Logo Image URL"];
      }
    },
    [cashierOtpSubmit.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.login.message = action.payload.data.message;
        state.login.messageAr = action.payload.data["message Ar"];
        state.login.cashierID = action.payload.data.CashierID;
        state.login.startTimeOut = true;
      }
    },
    [sendOTPRedeem.fulfilled]: (state, action) => {
      state.scanBarcode.barcode = action.meta.arg.barcode;
      state.burnCode.voucherValue = action.payload.data["eVoucher Value"];
    },
    [burnVouch.fulfilled]: (state, action) => {},
  },
  reducers: {
    handleScanBarcode: (state, action) => {
      state.scanBarcode.barcode = action.payload.barcode;
      state.scanBarcode.barcodeModalShow = false;
    },
    handleResetRedeem: (state, action) => {
      state.scanBarcode.scanBarcodeStepActive = true;
      state.burnCode.burnBarcodeStepActive = false;
      state.scanBarcode.barcode = "";
      state.scanBarcode.startTimer = false;
    },
    handleTimer: (state, action) => {
      state.burnCode.minutes = action.payload.minutes;
      state.burnCode.seconds = action.payload.seconds;
    },
    handleResetVouchCode: (state, action) => {
      state.burnCode.voucherValue = "";
    },
    handleCashierLogout: (state, action) => {
      state.login.cashierID = "";
      state.scanBarcode.barcode = "";
      state.burnCode.voucherValue = "";
      state.login.startTimeOut = false;
    },
  },
});

export const {
  handleScanBarcode,
  handleResetRedeem,
  handleSecondStepActive,
  handleTimer,
  handleResetVouchCode,
  handleCashierLogout,
} = cashierSlice.actions;
export default cashierSlice.reducer;
