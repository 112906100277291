import i18n from "i18next";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import { toast } from "react-toastify";

const failedMessage = (message, messageAr, pendingMessage) => {
  toast.update(pendingMessage, {
    render: i18n.language === "ar" ? messageAr : message,
    type: "error",
    position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
    isLoading: false,
    transition: Flip,
    autoClose : 6000,
  });
};

export default failedMessage;
