import React, { useEffect, useState } from "react";
import { Form, Alert, Button, Row, Col, Badge } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  burnVouch,
  handleResetVouchCode,
} from "../../../redux/features/cashierSlice";
import { useTranslation } from "react-i18next";
import failedMessage from "../../messages/Failed";
import successMessage from "../../messages/Successful";
import { toast, Zoom } from "react-toastify";
import Timer from "./Timer";
import { useNavigate } from "react-router-dom";
import "./Redeem.css";

export default function RedeemSecondStep(props) {
  const secondStepValidate = Yup.object().shape({
    otp: Yup.string().trim().required("OTPReq"),
  });

  const dispatch = useDispatch();
  const vouchCode = useSelector(
    (state) => state.reducer.cashier.scanBarcode.barcode
  );
  const cashierID = useSelector(
    (state) => state.reducer.cashier.login.cashierID
  );

  const { t, i18n } = useTranslation();

  const secondActive = useSelector(
    (state) => state.customer.burnBarcode.active
  );
  const [status, setStatus] = useState(false);
  useEffect(() => {
    setStatus(secondActive);
  }, [secondActive]);

  const merchantName = useSelector(
    (state) => state.reducer.cashier.login.merchantName
  );

  const barcode = useSelector(
    (state) => state.reducer.cashier.scanBarcode.barcode
  );

  const time = new Date();
  time.setSeconds(time.getSeconds() + 600);

  const navigate = useNavigate();

  const voucherValue = useSelector(
    (state) => state.reducer.cashier.burnCode.voucherValue
  );

  window.addEventListener("beforeunload", () => {
    dispatch(handleResetVouchCode());
  });

  return (
    <div>
      <div className="redeem-step">
        {status === false && <div className="overlay-not-active"></div>}
        <h5 className="redeem-title mb-3">{t("secondStep")}</h5>
        <Alert key="warning" variant="warning" className="alert-redeem">
          <RiErrorWarningLine className="me-2 ft-large" />
          {t("redeemNote")}
        </Alert>
        <Formik
          initialValues={{ otp: "" }}
          onSubmit={(values) => {
            const loadReq = toast.loading(t("wait"), {
              transition: Zoom,
              position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
            });
            dispatch(
              burnVouch({
                redeemOTP: values.otp,
                vouchCode,
                cashierID,
              })
            ).then((res) => {
              if (res.payload.data.statusCode === 200) {
                successMessage(
                  res.payload.data.message,
                  res.payload.data["message Ar"],
                  loadReq
                );
                navigate("/cashier/home");
              } else {
                failedMessage(
                  res.payload.data.message,
                  res.payload.data["message Ar"],
                  loadReq
                );
              }
            });
          }}
          validationSchema={secondStepValidate}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <div className="otp-content px-3 pt-3 pb-2">
                    <Form.Group className="mb-3">
                      <Form.Label className="step-label">
                        {t("confirmOTP")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={6}
                        placeholder={t("EnterOTP")}
                        size="lg"
                        className="redeem-input"
                        id="otp"
                        name="otp"
                        onChange={handleChange}
                        value={values.otp}
                        onKeyPress={(e) => {
                          var char = String.fromCharCode(e.which);
                          if (!/[0-9]/.test(char)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.otp && touched.otp && (
                        <Form.Text className="text-redeem-error">
                          {t(errors.otp)}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Text>
                      {t("otpExpired")}
                      <span>
                        <Timer expiryTimestamp={time} />
                      </span>
                    </Form.Text>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="text-center mt-3">
                    <Button
                      variant="danger"
                      className="redeem-btn px-5 py-2"
                      size="sm"
                      type="submit"
                    >
                      {t("redeem")}
                    </Button>
                  </div>
                </Col>
                <Col xs={12} className="mt-4 text-center gift-content">
                  {t("giftValue")}:
                  <Badge bg="" className="otp-icon-content ms-2 px-4 py-2">
                    {voucherValue ? voucherValue : 0} EGP
                  </Badge>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

        {
          //<div className="overlay-not-active"></div>
        }
      </div>
    </div>
  );
}
