import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import i18n from "i18next";
import ScanBarcode from "../../components/cashier/redeem/ScanBarcode";

export const verifyReceipt = createAsyncThunk(
  "customer/verifty/receipt",
  async (info, { rejectWithValue }) => {
    const receiptData = new FormData();
    receiptData.append("merchantID", info.issuerID);
    receiptData.append("receipt value", info.receiptValue);
    receiptData.append("Function", "VerifyReceipt");
    receiptData.append("receipt file", info.receiptFile);
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/lb54fb4jikhzcavkfa31so8xwxi03bwk",
        receiptData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const sendOTP = createAsyncThunk(
  "customer/send/OTP",
  async (info, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://hook.eu1.make.com/gwq4wy0e45qy599rar7quiycalxvear7",
        {
          "First Name": info.firstName,
          "Last Name": info.lastName,
          Gender: info.gender,
          "Mobile Number": info.mobileNumber,
          Function: "Send OTP",
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createVoucher = createAsyncThunk(
  "customer/voucher/create",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://hook.eu1.make.com/gwq4wy0e45qy599rar7quiycalxvear7",
        {
          "OTP Code": data.otp,
          "Mobile Number": data.mobileNumber,
          IssuerID: data.issuerID,
          AcquirerID: data.merchantID,
          "eVoucher Value": data.voucherValue,
          Function: "Create eVoucher",
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  firstStep: {
    IssuerID: "",
    croppedImageURL: "",
    ReceiptFileImage: null,
    receiptCapturedImage: "",
    message: "",
    messageAr: "",
    vouchers: [],
  },
  secondStep: {
    merchantID: "",
    merchantName: "",
    voucherValue: "",
  },
  thirdStep: {
    firstName: "",
    lastName: "",
    gender: "",
    message: "",
    messageAr: "",
    OTP: "",
    mobileNumber: "",
  },
  gift: {
    voucherLink: "",
    message: "",
    messageAr: "",
  },
  capture: {},
  otpredeem: {
    start: false,
  },
  scanBarcode: {
    active: true,
  },
  burnBarcode: {
    active: false,
  },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    handleCaptureReceipt: (state, action) => {
      state.firstStep.receiptCapturedImage = action.payload.capturedImage;
      state.firstStep.ReceiptFileImage = action.payload.cropData;
    },
    handleChooseVoucher: (state, action) => {
      state.secondStep.merchantID = action.payload.merchantID;
      state.secondStep.merchantName = action.payload.merchantName;
      state.secondStep.voucherValue = action.payload.voucherValue;
    },
    handleStartRedeemTimer: (state, action) => {
      state.otpredeem.start = action.payload.startTimer;
    },
    handleSecondStepActive: (state, action) => {
      state.scanBarcode.active = action.payload.ScanBarcodeActive;
      state.burnBarcode.active = action.payload.burnBarcodeActive;
    },
    handleResetCapturedImage: (state, action) => {
      state.firstStep.receiptCapturedImage = "";
    },
  },
  extraReducers: {
    [verifyReceipt.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.firstStep.vouchers = action.payload.data["eGift Vouchers"];
        state.firstStep.IssuerID = action.meta.arg.issuerID;
      }
    },
    [sendOTP.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.thirdStep.mobileNumber = action.meta.arg.mobileNumber;
      }
    },
    [createVoucher.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.gift.voucherLink = action.payload.data["eVoucher Link"];
      }
    },
  },
});

export const {
  handleCropImage,
  handleChooseVoucher,
  handleCaptureReceipt,
  handleStartRedeemTimer,
  handleSecondStepActive,
  handleResetCapturedImage,
} = customerSlice.actions;
export default customerSlice.reducer;
